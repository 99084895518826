@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&display=swap');

html {
  scroll-behavior: smooth;
  font-family: "Noto Sans Hebrew", sans-serif;

}

/* Set a specific size for the icons in all screen sizes */
.md-icon {
  width: 20px; /* Set the width of the icons */
  height: 20px; /* Set the height of the icons */
}


.bg-hero {
  background-image: url('../src/images/header.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-subjects {
  background-image: url('../src/images/subjects.png');
  background-size: cover;

}

.bg-info {
  background-image: url('../src/images/hero.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-more {
  background-image: url('../src/images/header.png');
  background-size: cover;

}

.bg-footer {
  background-image: url('../src/images/subjects.png');
  background-size: cover;

}

.bg-card {
  background-image: url('../src/images/hero.jpg');
  background-size: cover;

}

.bg-brown {
  background-image: url('../src/images/brown.png');
  background-size: cover;

}


.swiper2 .swiper-pagination-bullet-active {
  background-color: black !important;
}

.swiper2 .swiper-pagination-bullet {
  background-color: black
}

.arrow {
  z-index: 100;
  position: absolute;
  top: 50%;
  margin-top: -5px;
}

.arrow-left2 {
  left: 15%;
  /* Adjust the left positioning as needed */
}

.arrow-right2 {
  right: 15%;
  /* Adjust the right positioning as needed */
}


@media (max-width: 992px) {
  .arrow-left2 {
    left: 2%;
    /* Adjust the left positioning as needed */
  }

  .arrow-right2 {
    right: 2%;
    /* Adjust the right positioning as needed */
  }
}


.dotbg {
  width: 100%;
  height: 100%;
  /* Add your background pattern here */
  background-color: #ffffff;
  background-image: radial-gradient(rgba(12, 12, 12, 0.171) 2px, transparent 0);
  background-size: 30px 30px;
  background-position: -5px -5px;
}



.card {
  transition: all 0.2s;
  position: relative;
  max-width: 100%;
  /* Ensure card does not exceed viewport width */
  overflow: hidden;
  /* Hide overflow at the card level if necessary */
}

.card-inner {
  width: 100%;
  /* Use the full width of the card */
  max-width: 1000px;
  /* Max width to control the size on larger screens */
  margin: auto;
  /* Center the card-inner if it's smaller than the viewport */
  padding: 24px;
  background: rgba(255, 255, 255, .05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  overflow-x: auto;
  /* Allow horizontal scrolling within card-inner */
  overflow-y: hidden;
  /* Optionally, hide vertical overflow */
}


.circle {
  width: 100px;
  height: 100px;

  background: radial-gradient(#947a5e, #b99976);
  border-radius: 50%;
  position: absolute;
  animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle:nth-child(1) {
  top: -25px;
  left: -25px;
}

.circle:nth-child(2) {
  bottom: -25px;
  right: -25px;
  animation-name: move-down1;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}

@media (max-width: 768px) {
  .card-inner {
    overflow-x: auto;
    /* Ensure horizontal scrolling is enabled for smaller screens */
  }
}



.heart-container {
  --heart-color: #e8b3a3;
  position: relative;
  width: 50px;
  height: 50px;
  transition: .3s;
}

.heart-container .checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.heart-container .svg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-container .svg-outline,
        .heart-container .svg-filled {
  fill: var(--heart-color);
  position: absolute;
}

.heart-container .svg-filled {
  animation: keyframes-svg-filled 1s;
  display: none;
}

.heart-container .svg-celebrate {
  position: absolute;
  animation: keyframes-svg-celebrate .5s;
  animation-fill-mode: forwards;
  display: none;
  stroke: var(--heart-color);
  fill: var(--heart-color);
  stroke-width: 2px;
}

.heart-container .checkbox:checked~.svg-container .svg-filled {
  display: block
}

.heart-container .checkbox:checked~.svg-container .svg-celebrate {
  display: block
}

@keyframes keyframes-svg-filled {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
    filter: brightness(1.5);
  }
}

@keyframes keyframes-svg-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
    display: none;
  }
}